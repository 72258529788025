/**
 * @author emil.ohman@svenskaspel.se (Emil Öhman)
 */
import { useRef } from 'react';
import PropTypes from 'prop-types';
import './../../stylesheets/step-indicator.less';

const StepIndicator = ({
  block,
  branding,
  children,
  className,
  duration,
  isClosed,
  onSelect,
  onAnimationEnd,
  showDone,
  useAnimation
}) => {
  const classNames = ['step-indicator'];
  const stepIndicatorEl = React.useRef(null);
  const animationEventListener = useRef();
  const animationEventTimeout = useRef();

  if (block) {
    classNames.push('step-indicator-block');
  }

  if (branding) {
    classNames.push(`step-indicator-${branding}`);
  }

  if (duration) {
    classNames.push(`step-indicator-animation-${duration}`);
  }

  if (isClosed) {
    classNames.push('step-indicator-closed');
  }

  if (className) {
    classNames.push(className);
  }

  const onSelectedStep = (name, stepRef) => {
    if (stepRef.current && stepRef.current.classList.contains('step-indicator-step-active')) {
      return;
    }
    if (stepRef.current) {
      if (useAnimation) {
        animateToStep(stepRef.current);
      } else {
        // If animation is in progress, stop it.
        const oldActiveItem = stepIndicatorEl.current.querySelector('.step-indicator-step-activating');

        if (oldActiveItem) {
          oldActiveItem.removeEventListener('transitionend', animationEventListener.current);
          oldActiveItem.classList.remove('step-indicator-step-activating');
        }
        stepRef.current.classList.add('step-indicator-step-active');
      }

      if (onSelect) {
        onSelect(name);
      }
    }
  };

  const animateToStep = (newSelectedStep) => {
    const allSteps = stepIndicatorEl.current.querySelectorAll('.step-indicator-step');
    let oldActiveItem = stepIndicatorEl.current.querySelector('.step-indicator-step-active');

    if (!oldActiveItem) {
      oldActiveItem = stepIndicatorEl.current.querySelector('.step-indicator-step-activating');
    }

    newSelectedStep.querySelector('.step-indicator-step-active-old-content').innerHTML =
      oldActiveItem.querySelector('.step-indicator-step-content').innerHTML;

    let oldIndex = 0;
    let itemIndex = 0;

    for (let i = 0, length = allSteps.length; i < length; i++) {
      const currentStep = allSteps[i];

      if (currentStep === oldActiveItem) {
        oldIndex = i;
      }

      if (currentStep === newSelectedStep) {
        itemIndex = i;
      }
    }

    // If stop here if we try to activate our self
    if (oldIndex === itemIndex) {
      return;
    }

    const startX = oldActiveItem.offsetLeft - newSelectedStep.offsetLeft - (oldIndex === 0 ? 19 : 0);
    const activeItemContainer = newSelectedStep.querySelector('.step-indicator-step-active-container');

    activeItemContainer.style.width = `${oldActiveItem.offsetWidth + (oldIndex === 0 ? 21 : 0)}px`;
    activeItemContainer.style.transform = `translate3d(${startX}px, 0, 0)`;

    clearTimeout(animationEventTimeout.current);
    animationEventTimeout.current = setTimeout(() => {
      if (showDone) {
        let delayCounter = 0;
        for (let j = oldIndex; j < itemIndex; j++) {
          allSteps[j].classList.add('step-indicator-step-done');
          allSteps[j].querySelector('.icon').style.transitionDelay = `${delayCounter * 0.2 + 0.2}s`;
          delayCounter++;
        }
      }

      oldActiveItem.classList.remove('step-indicator-step-active');
      oldActiveItem.classList.add('step-indicator-step-deactivating');
      newSelectedStep.classList.add('step-indicator-step-activating');
      activeItemContainer.style.width = `${newSelectedStep.offsetWidth}px`;
      activeItemContainer.style.transform = 'translate3d(0, 0, 0)';
    }, 20);

    // /////////////////////
    // animationEndEvent
    // ///////////////////
    const animationEndEvent = () => {
      newSelectedStep.removeEventListener('transitionend', animationEndEvent);
      animationEventListener.current = null;
      oldActiveItem.classList.remove('step-indicator-step-deactivating');
      newSelectedStep.classList.remove('step-indicator-step-activating');
      for (let i = 0, length = allSteps.length; i < length; i++) {
        const currentStep = allSteps[i];
        currentStep.classList.remove('step-indicator-step-active');
      }
      newSelectedStep.classList.add('step-indicator-step-active');
      if (onAnimationEnd) {
        onAnimationEnd(itemIndex);
      }
    };
    newSelectedStep.addEventListener('transitionend', animationEndEvent);
    animationEventListener.current = animationEndEvent;
  };
  const childrenWithEvent = React.Children.map(children, (child) => React.cloneElement(child, {
    onSelected: onSelectedStep,
    useAnimation
  }));
  return (
    <div className={classNames.join(' ')} ref={stepIndicatorEl}>
      {childrenWithEvent}
    </div>
  );
};

StepIndicator.propTypes = {
  block: PropTypes.bool,
  branding: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  duration: PropTypes.string,
  isClosed: PropTypes.bool,
  onAnimationEnd: PropTypes.func,
  onSelect: PropTypes.func,
  showDone: PropTypes.bool,
  useAnimation: PropTypes.bool
};

StepIndicator.defaultProps = {
  block: false,
  branding: 'default',
  showDone: false,
  useAnimation: false
};

export default StepIndicator;
